import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SearchTiendaComponent, SearchType } from 'src/app/components/simulador/search-tienda/search-tienda.component';
import * as Interfaces from 'src/app/interfaces/ISimulador';
import { EMPTY, Subscription } from 'rxjs';
import { RebateBackendService } from 'src/app/services/rebate/rebate.backend.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { errors } from 'src/app/constants/constants.errors';
import { NgxSpinnerService } from "ngx-spinner";
import { CommonsModule, DropDownOptions } from 'src/app/services/commons/commons.service';
import { MatOptionSelectionChange } from '@angular/material/core';
import { SimuladorSellOut } from 'src/app/classes/simuladorSellOut';
import { SearchProveedorComponent } from 'src/app/components/simulador/search-proveedor/search-proveedor.component';
import { Proveedor } from 'src/app/classes/proveedor';
import { SearchMarcaComponent } from 'src/app/components/simulador/search-marca/search-marca.component';
import { Acuerdo } from 'src/app/classes/acuerdo';
import { AcuerdoSellOutComponent } from 'src/app/shared/components/acuerdo-sell-out/acuerdo-sell-out.component';


@Component({
  selector: 'app-simulador-sell-out',
  templateUrl: './simulador-sell-out.component.html',
  styleUrls: ['./simulador-sell-out.component.scss']
})

export class SimuladorSellOutComponent {
  private subscription!: Subscription;
  public title?: string;
  public spinnerMessage: string = 'Loading...';
  public dataSource: SimuladorSellOut;
  public readonly cabeceraTabla: string[] = ['num', 'jerarquia', 'descrjerarquia', 'fecdesde', 'fechasta', 'aporte', 'unidad', 'aporte_total', 'organizacion', 'accion'];
  public organizaciones!: Interfaces.OrganizacionOptions[]; //= [{a_valor:'tienda',c_parametro:'tienda', searchType:SearchType.TIENDA, selected:false}];
  public country!: string;
  public searchButtonTitle!: string;
  public sku: string = "";
  public aporte: string = "";
  public fecDesde!: Date;
  public fecHasta!: Date;
  public simular: boolean = false;
  public simulado: boolean = false;
  public organizacion: number = 0;
  public tiendas: Interfaces.Tiendas[] = [];
  public makeAcuerdo: boolean = false;
  public tablaSimulador: Interfaces.FilaTablaSimuladorSellOut[];
  public rutFullProv: string = '';
  public seltiendas: boolean = false;
  public claseFilaNumber: string = 'right'
  public claseFilaText: string = 'left'
  public parametros: Interfaces.Parametros[] = [];

  //variables de validacion diferencia max permitido 
  public porcMargenPermitido: number = 0;
  public permiteAcuerdoxDif: boolean=false;
  public montoDiferencia: number=0;

  //variables que sirven para buscar los datos de estos cuando cargan el excel.
  //public orgExcel: Interfaces.OrganizacionOptions={c_parametro:"0", a_valor: '', searchType: SearchType.TIENDA, selected: true, path:''};
  //public tiendasExcel: Interfaces.Tiendas[]=[];

  constructor(
    private activatedroute: ActivatedRoute,
    private rbtService: RebateBackendService,
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    private commonService: CommonsModule) {
    this.dataSource = new SimuladorSellOut(rbtService, commonService)
    this.tablaSimulador = this.dataSource.tabla
  }

  ngOnInit(): void {
    this.setTitle();
    //this.auth.getToken().then(token=>console.log(token))
    this.loading();
    this.auth.getCountry().then(value => {
      this.country = value
      this.dataSource.country=value
      }
    )
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


  async loading() {
    this.spinner.show('simulador');
    this.spinnerMessage = "Cargando datos...";

    let p1 = new Promise<void>((res, rej) => {
      this.commonService.loadDropDown<Interfaces.OrganizacionOptions>(DropDownOptions.Organizacion)
        .then((data) => {
          this.organizaciones = data;
          this.organizaciones.forEach((ele, idx) => {
            ele.path = `/organizaciones/opciones/${ele.c_parametro}`
            ele.searchType = (ele.a_valor.toUpperCase().includes('TIENDA')) ? SearchType.TIENDA : SearchType.LOCAL
            ele.selected = (idx == 0)
            if (ele.selected) {
              this.organizacion = CommonsModule.stringToNumber(ele.c_parametro.toString())
              this.searchButtonTitle = (ele.searchType == SearchType.LOCAL) ? 'Locales' : 'Tiendas';
              if (['7', '8'].includes(String(this.organizacion.toString()))) {
                this.tiendas = [{ name: (ele.searchType == SearchType.LOCAL) ? 'todos los locales' : 'todas las tiendas', value: '0' }];
              }
            }
          });
          res()
        })
        .catch(err => rej('Error tipos: ' + err))
    })

    //hago la carga de los parametros como si lo cargara en un dropdown pq lo puedo usar mas facil,
    //ya que todas las pantallas cargan los datos iniciales de esta forma y queda mas limpio el codigo.
    let p2 = new Promise<void>((res, rej) => {
      this.commonService.loadDropDown<Interfaces.Parametros>(DropDownOptions.parametros)
        .then((data) => {
          this.parametros = data;
          this.parametros.forEach(param => {
            if (param.cparametro == 'DELTASIM') {
              this.porcMargenPermitido = CommonsModule.stringToNumber(param.valor.toString())
            }
          })
          res()
        })
        .catch(err => rej('Error tipos: ' + err))
    })

    let results = await Promise.allSettled([p1, p2])
    let errorMsg = results.reduce((prv, curr) => {
      if (curr.status == 'rejected') {
        return (prv != "") ? `${prv}<br>${curr.reason}` : curr.reason
      } else {
        return prv
      }
    }, "")

    if (errorMsg != "") {
      this.commonService.error("Error", errorMsg, 'Aceptar', true)
    }
    this.spinner.hide('simulador')
  }


  private setTitle() {
    this.subscription = this.activatedroute.data.subscribe(data => {
      this.title = data["title"]
    })
  }

  buscarProveedor(event: any) {
    this.spinner.show('simulador');
    this.spinnerMessage = "Verificando Proveedor";
    this.dataSource.proveedor.setProveedor(event.target.value)
      .then(() => this.rutFullProv = this.dataSource.proveedor.proveedorFullRut)
      .catch(err => {
        if (err instanceof HttpErrorResponse) {
          this.commonService.error(errors.ErrorBuscarProveedor.message, (err.status === 404) ? errors.ProveedorNoExiste.message : err.message, 'Aceptar', true)
        } else {
          this.commonService.error(errors.ErrorBuscarProveedor.message, err, 'Aceptar', true)
        }
      })
      .finally(() => this.spinner.hide('simulador'))
  }

  openDialogProv() {
    this.dataSource.proveedor.setValues('','','','')
    this.rutFullProv=''
    this.commonService.openDialog<Proveedor, null>(SearchProveedorComponent).subscribe(
      {
        next: result => { 
          if (result != null){ 
            var provs :Proveedor[]=[result]
            for (let prov of provs){
              this.dataSource.proveedor.setValues(prov.nrut, prov.vendor_name,prov.xdvrut,prov.vpc_tech_key)
              this.rutFullProv = this.dataSource.proveedor.proveedorFullRut
            } 
          }
        }
      }
    )
  }

  setSearchType(option: Interfaces.OrganizacionOptions) {
      this.organizaciones.forEach(ele => ele.selected = (ele.c_parametro == this.organizacion.toString()))
      this.searchButtonTitle = (option.searchType == SearchType.LOCAL) ? 'Locales' : 'Tiendas';
      if (['7', '8'].includes(String(this.organizacion.toString()))) {
        this.tiendas = [{ name: (option.searchType == SearchType.LOCAL) ? 'todos los locales' : 'todas las tiendas', value: '0' }];
      } else {
        this.spinnerMessage = `Buscando ${(option.searchType == SearchType.LOCAL) ? 'locales' : 'tiendas'}...`
        this.spinner.show('simulador');
        this.tiendas = [];
        this.rbtService.callBackendGet<Interfaces.DatosTiendaLocal[]>(option.path as string).subscribe({
          next: data => {
            data.forEach(ele => {
              this.tiendas.push({
                name: `${ele.org_lvl_number} ${ele.org_name_full}`,
                value: ele.org_lvl_child.toString()
              })
            })
          },
          error: _ => { this.spinner.hide('simulador') },
          complete: () => { this.spinner.hide('simulador') }
        })
      }
  }

  openDialogTiendas() {
    const st = this.organizaciones.filter(ele => ele.selected)[0].searchType;
    this.commonService.openDialog<Interfaces.FilaTablaSearch, null>(SearchTiendaComponent, { searchType: st }).subscribe(
      {
        next: result => {
          if (result != null) this.tiendas = (st == SearchType.LOCAL) ? result.local : result.tienda;
          this.seltiendas = true;
        }
      }
    )
  }

  openDialogProveedores() {
    this.commonService.openDialog<Proveedor, null>(SearchProveedorComponent).subscribe(
      {
        next: result => { if (result != null) this.dataSource.pbc = [result] }
      }
    )
  }

  openDialogMarcas() {
    this.commonService.openDialog<Interfaces.Marca[], null>(SearchMarcaComponent).subscribe(
      {
        next: result => { if (result != undefined && result.length > 0) this.dataSource.marcas = result }
      }
    )
  }

  addProduct(rows: Interfaces.AddProductStructSellOut[] = [{ sku: this.sku, prd_lvl_child: 0, aporte: 0, name_full: '', fecDesde: this.fecDesde, fecHasta: this.fecHasta, organizacion: this.organizaciones.filter(ele => ele.selected)[0], descrOrg: this.organizaciones.filter(ele => ele.selected)[0].a_valor, tiendaLocal: this.tiendas, seltiendas: this.seltiendas }]) {
    this.spinner.show('simulador')
    this.spinnerMessage = "Verificando Jerarquía/SKU"

    rows.forEach((prod, idx) => {

      let valor:string
      if (prod.aporte==0){
        valor=this.aporte
      }
      else{
        let parts = CommonsModule.numParts(this.country)
        valor = prod.aporte.toString().replaceAll(".",parts.decimals)
      }

      
      let aporte = CommonsModule.strToNum(this.country, valor, 'aporte')
      if (aporte.error != "") {
        this.commonService.error("Error", aporte.error, 'Aceptar', true)
        return
      } else {
        if (!aporte.decimalFound){
          prod.aporte = CommonsModule.stringToNumber(aporte.miles)
        }
        else{
          prod.aporte=CommonsModule.stringToNumber(aporte.miles + aporte.decimalPart.replaceAll(aporte.decimalPart,".") + aporte.decimales)
        }
      }
    })

    this.dataSource.addProducts(rows)
      .then(
        (valor) => {
          this.makeAcuerdo=false
        if (valor){
          this.updateDataSource() 
        }
        this.simular = true;
        this.sku = '';
        this.aporte = "";
        this.fecDesde = ('' as unknown as Date);
        this.fecHasta = ('' as unknown as Date);
        this.organizaciones[0].searchType = (this.organizaciones[0].a_valor.toUpperCase().includes('TIENDA')) ? SearchType.TIENDA : SearchType.LOCAL
        this.organizaciones[0].selected = true;
        if (this.organizaciones[0].selected) {
          this.organizacion = CommonsModule.stringToNumber(this.organizaciones[0].c_parametro.toString())
          if (['7', '8'].includes(String(this.organizacion))) {
            this.tiendas = [{ name: (this.organizaciones[0].searchType == SearchType.LOCAL) ? 'Todos los locales' : 'Todas las tiendas', value: '0' }];
          }
        }
        this.seltiendas = false;
      },
      (reason)=>{
        this.commonService.error("Error", (reason as Error).message, 'Aceptar', true);
      }
      
      )
      .catch(reason => {
        this.commonService.error("Error", (reason as Error).message, 'Aceptar', true);
      }
      )
      .finally(() => { this.spinner.hide('simulador'); this.updateDataSource() })
  }

  cleanDataSource() {
    this.dataSource.cleanTabla();
    this.simular = false;
    this.makeAcuerdo = false;
    this.simulado = false;
    this.tablaSimulador = this.dataSource.tabla
  }

  validaGrilla() {
    let montoMayor: number=0;
    let indice : number =0;
    let indiceAjuste: number=0;
    this.tablaSimulador.forEach((ele,index) => {
      
      if (ele.aporte == 0) {
        this.claseFilaNumber = 'right naranjo'
        this.claseFilaText = 'left naranjo'
      }
      else {
        this.claseFilaNumber = 'right'
        this.claseFilaText = 'left'
      }
    })

    //agrego el monto de diferencia al producto que tiene mas aporte total
    if (this.permiteAcuerdoxDif){
      this.dataSource.totalSimulador=this.dataSource.totalSimulador + this.montoDiferencia
      //tengo que recorrer la grilla para recalcular los aportes totales de cada producto entre la cantidad y el aporte unitario.
      this.tablaSimulador.forEach((ele, index) => {
        if (CommonsModule.stringToNumber(ele.unidad) ==0){
          ele.aporte_total=ele.aporte * CommonsModule.stringToNumber(ele.unidad) //recalculo el aporte total de cada producto.
        }
        if (montoMayor<ele.aporte_total){
          montoMayor=ele.aporte_total
          indice=index
        }
      })
      //termino de hacer el recalculo y ahora tengo que agregar la diferencia al producto con mayor aporte total
      this.tablaSimulador[indice].aporte_total=this.tablaSimulador[indice].aporte_total + this.montoDiferencia;
      //tengo que recalcular su aporte unitario
      //dividiendo el aporte total del producto con mas aporte por sus unidades y sacarle los decimales.
      this.tablaSimulador[indice].aporte=Math.floor(this.tablaSimulador[indice].aporte_total / CommonsModule.stringToNumber(this.tablaSimulador[indice].unidad))
      //ahora tengo que recalcular el aporte total de cada producto sumandolos y al monto simulado nuevo le resto este.
      let montototal: number=0;
      this.tablaSimulador.forEach((ele) => {
        if (ele.display){
          montototal = montototal + (ele.aporte * CommonsModule.stringToNumber(ele.unidad)) //recalculo el aporte total de los productos y los sumo
        }
      })
      //calculo la diferencia entre el total simulado nuevo y este total recalculado
      let difrec= this.dataSource.totalSimulador - montototal
      //esta diferencia tengo que colocarla en la linea de ajuste en el aporte unitario.
      //recorro la tabla completa que se genera despues del simulador, no la que tiene la pantalla del simulador sino que
      //la del simulador 
      this.dataSource.tabla.filter(ele => !ele.display).forEach(ele=>{ //saco solo el elemento que tiene el display == false, ya que es el ajuste.
        if (!ele.display){
          ele.aporte= difrec
        }
      })
      
    }
  }

  updateDataSource() {
    this.tablaSimulador = this.dataSource.tabla.filter(ele => ele.display)
  }

  realizaSimulacion() {
    this.spinner.show('simulador')
    this.spinnerMessage = "Realizando simulación..."
    this.dataSource.doSimulacion()
      .then(() => {
        this.makeAcuerdo = true
        this.simulado = true;
      }
      )
      .catch(reason =>
        this.commonService.error("Error", reason, 'Aceptar', true
        ))
      .finally(() => {
        this.spinner.hide('simulador');
        this.updateDataSource()
        this.validaMaxPermitido()
        this.validaGrilla();
      })
  }

  uploadExcel(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.spinner.show('simulador');
      this.spinnerMessage = "Validando archivo Excel ...";
      this.dataSource.arrayExcel=[];
      this.cleanDataSource();
      CommonsModule.readExcel<Interfaces.SimuladorExcelStructSellOut>(file)
        .then(data => {

          this.addProduct(data.map<Interfaces.AddProductStructSellOut>((ele,idx) => {

            let tiendasExcel: Interfaces.Tiendas[] = []
            let orgExcel: Interfaces.OrganizacionOptions = { c_parametro: "0", a_valor: '', searchType: SearchType.TIENDA, selected: true, path: '' };      
            let tien: string[]=ele.LOCAL_ID.toString().split(';');
            for (let x: number=0; x<tien.length; x++){
              tiendasExcel.push({value: tien[x], name:''})
            }
            
            if (!CommonsModule.validaFormatoFecha(ele.FECHA_COBRO_DESDE)){
              let indice = idx+1;
              throw 'Fecha desde ' + ele.FECHA_COBRO_DESDE +  ' en línea ' + indice + ' no tiene formato correcto, debe ser DD-MM-YYYY ej: 01-01-2023'
            }
            if (!CommonsModule.validaFormatoFecha(ele.FECHA_COBRO_HASTA)){
              let indice = idx+1;
              throw 'Fecha hasta ' + ele.FECHA_COBRO_DESDE +  ' en línea ' + indice + ' no tiene formato correcto, debe ser DD-MM-YYYY ej: 01-01-2023'
            }

            let fecDesde: string = CommonsModule.funcDateExcel(CommonsModule.stringToNumber(ele.FECHA_COBRO_DESDE.toString()));
            let fechaDesde: Date= CommonsModule.stringToFecha(fecDesde);
            let fecHasta: string = CommonsModule.funcDateExcel(CommonsModule.stringToNumber(ele.FECHA_COBRO_HASTA.toString()));
            let FechaHasta: Date= CommonsModule.stringToFecha(fecHasta);

            let montoExcel: number= ele.MONTO

            return {
              sku: String(ele['SKU_ID']),
              aporte: montoExcel,
              prd_lvl_child: 0,
              name_full: '',
              fecDesde:  fechaDesde, 
              fecHasta: FechaHasta, 
              organizacion: orgExcel,
              descrOrg: '',
              tiendaLocal: tiendasExcel,
              seltiendas: false
            }
          }))
        })
        .catch(reason => {
          this.spinner.hide('simulador')
          this.commonService.error(errors.LoadingExcelError.message, reason, 'Aceptar', true)
        })
    }
  }

  validaMaxPermitido(){
    //valido que diferencias de totales no supere el monto del parámetro en la db DELTASIM
    //parametro que viene en %, no es numérico
    let montoMaxPermitodo = this.dataSource.totalIngresado * (this.porcMargenPermitido/100) //Y
    let montoRestado = this.dataSource.totalIngresado - this.dataSource.totalSimulador //X
    console.log(montoRestado)
    if (montoRestado <= montoMaxPermitodo){
      //si crea acuerdo
      this.permiteAcuerdoxDif=true;
      this.montoDiferencia=montoRestado
    }
    else{
      //no crea acuerdo
      this.permiteAcuerdoxDif=false;
      this.montoDiferencia=0;
    }
  }

  openDialogCrearAcuerdo() {
    if (this.permiteAcuerdoxDif) {
      this.commonService.openDialog<Acuerdo, SimuladorSellOut>(AcuerdoSellOutComponent, {
        organizacion: this.organizaciones.filter(ele => ele.selected)[0].a_valor, parametroVer: false, btnCerrar: 'Cancelar'
        , title: 'Creación de acuerdo', mostrarFilaAgregar: false, addpod: false
      }, { data: this.dataSource, height: '95%', disableClose: true }).subscribe(
        {
          next: result => {
            if (result != undefined && result.nAcuerdo > 0) {
              this.commonService.alert('accent', 'Número acuerdo generado', 'Número de acuerdo generado: ' + result.nAcuerdo, 'Aceptar', true)
            }
          }
        }
      )
    }
    else{
      this.commonService.error('Atención', 'No se puede crear el acuerdo, hay filas con aporte en cero y/o negativo, corrija y reintente.', 'Aceptar', true)
    }
  }

  showDataTiendas() {
    this.organizaciones.forEach(ele=>{
      if (ele.c_parametro==this.organizacion.toString()){
        ele.selected=true;
      }
      else{
        ele.selected=false;
      }
    })
    let st = this.organizaciones.filter(ele => ele.selected)[0].searchType
    let colTitle = (st == SearchType.LOCAL) ? 'Locales' : 'Tiendas';
    this.commonService.showData(this.tiendas, [colTitle])
  }

  showDataPBC() {
    let data = this.dataSource.pbc.map(ele => { return { rut: ele.proveedorFullRut, nombre: ele.vendor_name } })
    this.commonService.showData(data, ["RUT", "Nombre"])
  }

  showDataMarcas() {
    this.commonService.showData(this.dataSource.marcas, ["Marca", "Descripción"])
  }

  validaFecha(event: any, campo: string) {
    let fecha: string = event.target.value
    if (fecha == null) {
      this.commonService.error("Error", "Fecha inválida", 'Aceptar', true);
      if (campo == 'desde') {
        this.fecDesde = new Date()
      } else {
        this.fecHasta = new Date()
      }
    }
  }

  // validaMonto(event: any) {
  //   let group: string = '';
  //   let decimal: string = ''
  //   const parts = new Intl.NumberFormat("es-" + this.country.toUpperCase()).formatToParts(12345.6);
  //   parts.forEach(part => {
  //     if (part.type == 'group') {
  //       group = part.value
  //     }
  //     if (part.type == 'decimal') {
  //       decimal = part.value
  //     }
  //   }
  //   )
  //   this.aporte = event;
  //   this.aporte = this.aporte.replaceAll(group, "")
  //   this.aporte = this.aporte.replaceAll(decimal, ".")
  // }

  // Numero(valor: string): number {
  //   if (!isNaN(Number(valor))) {
  //     return Number(valor);
  //   }
  //   else {
  //     return 0
  //   }
  // }

  copiar(i: number) {
    this.sku = this.dataSource.tabla[i].jerarquia;
    this.fecDesde = this.dataSource.tabla[i].fecdesde;
    this.fecHasta = this.dataSource.tabla[i].fechasta;
    this.aporte = "" + this.dataSource.tabla[i].aporte_total;
    this.organizacion = Number(this.dataSource.tabla[i].organizacion.c_parametro)
    this.organizaciones.forEach(ele=>{
      if (ele.c_parametro==this.organizacion.toString()) this.searchButtonTitle = (ele.searchType == SearchType.LOCAL) ? 'Locales' : 'Tiendas';
    })
    this.tiendas=[]
    this.dataSource.tabla[i].tiendaLocal.forEach(ele=>{
      //hay que mandar primero el nombre ya que es solo este elemento el que toma despues para mostrarlo en la 
      //grilla al presionar el ver, si se manda el value muestra el value.
      this.tiendas.push({name: ele.name, value:ele.value})
    })

  }

  eliminar(i: number) {
    this.dataSource.tabla.splice(i, 1)
    this.updateDataSource();
    this.dataSource.calculoTotalIngresado();
    this.makeAcuerdo=false
    if (this.dataSource.tabla.length == 0) {
      this.cleanDataSource();
      this.simular = false;
    }
    if (this.dataSource.tabla.length == 1 && !this.dataSource.tabla[0].display) {
      this.cleanDataSource();
      this.simular = false;
    }

  }

  // keyDown($event: any) {
  //   if ($event.keyCode >= 65 && $event.keyCode <= 90) {
  //     $event.preventDefault();
  //   }
  // }
  
  keyDown($event: any) {
    let charCode = String.fromCharCode($event.which).toLowerCase();
    if ($event.ctrlKey && (charCode === 'v' || charCode==='c')) {
      //console.log("si")
    }
    else{
      if ($event.metaKey && (charCode==='v' || charCode==='c')){
        //console.log("si command")
      }
      else{
        if ($event.keyCode >= 65 && $event.keyCode <= 90) {
          $event.preventDefault();
        }    
      }
    }
  }

  onKeyUp($event: any) {
    // if ($event.keyCode >= 65 && $event.keyCode <= 90) {
    //   $event.preventDefault();
    // } else {
      this.aporte = this.commonService.transformarMonto(this.country,this.aporte, 'aporte')
    }
  // }
}